import React from 'react'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import Moment from 'moment'

const NewsFeed = ({campaign, feed}) => {

  const BackgroundDiv = styled.div`
    background: ${props => props.background};
    color:  ${props => props.color}
  `
  return (
    <div className =" flex flex-col">
      <BackgroundDiv
        background= {campaign.color_primary}
        color={campaign.navbar_text_color}
        className ="template-info template-info--reduced-spacing mb-2 uppercase "
        >
        <p>News Feed</p>
      </BackgroundDiv>
      <div className="feed-container flex-1 bg-white min-h-0">
        {feed.map((feedItem) => (
          <FeedItem 
            key={`feed-item-${feedItem.id}`}
            id={feedItem.id}
            date={feedItem.created_at}
            title={feedItem.title}
            image={feedItem.image}/>
        ))}
      </div>
    </div>
  )
}


const FeedItem = ({id,date, title, image})=> {

  const thumbImageKey = 'rectangular_thumbnail_image';
  const thumbUrl = image[thumbImageKey] && image[thumbImageKey].url

  return (
    <NavLink to={`/news/${id}`} className="anchor-reset">
      <div className="px-5 py-2">
        <p className="gray">{Moment(date).format("LLL")}</p>
        <h3>{title}</h3>
        <div className="pt-3">
          <img className ="feed-thumb"src={thumbUrl} alt=""/>
        </div>
      </div>
    </NavLink>
  )
}

export default NewsFeed