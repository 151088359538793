import React, { Component } from 'react';
import '../../../assets/css/slider.scss';
import { connect } from 'react-redux';
import { NewsService } from '../../../services/index';
import { startFetch, endFetch } from '../../../actions/generalActions';
import NewsHandler from '../../shared/NewsHandler';
import BannerSlider from '../shared/BannerSlider'
import { withResizeContainer } from '../normalizer'
import ContentLayout from '../shared/ContentLayout';
import {prefix} from '../../../i18n'

const tNews= prefix('news')
class News extends Component{
  constructor(props){
    super(props);
    this.state={news: [] };
  }

  getNews(){
    this.props.startFetch();
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug
    }
    NewsService.getNews(payload)
      .then((response) => {
          this.setState({news: response.data.news})
          this.props.endFetch();
      })

  }
  componentDidMount(){
    if ( this.state.news.length === 0 ) {
      this.getNews()
    }
  }

  getNewsBanners(){
    const banners = []
    const { news } = this.state
    const imageSize = this.props.imageSize
    for(var i=0; i < this.state.news.length; i++){
      banners.push(
        {
          id: i, 
          src: news[i].image[imageSize] ? news[i].image[imageSize].url : '',
          url:news[i].url, 
          text: news[i].banner_text 
        }
      )
    }
    return banners;
  }

  render() {
    const { campaign } = this.props
    const { news } = this.state
    const banners = this.getNewsBanners()
    return (
      <div>
        <div className='font-quicksand' style={{backgroundColor: '#eaeaef'}}>
          <div className="row center-lg center-xs center-md slider">
            <div className="col-lg-12 col-md-12 col-xs-12">
              <BannerSlider data={banners} backgroundColor = {campaign.color_primary} borderColor = { campaign.color_secondary } />
            </div>
          </div>
          <ContentLayout title={tNews('title')} campaign={campaign}>
            {news.map((annoucement, key)=>(
              <NewsHandler
                key={annoucement.id}
                annoucement={annoucement}
                campaign={campaign}
                />
            ))}
          </ContentLayout>
        </div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign
  }
}

function mapDispatchToProps(dispatch){
  return{
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}
const NewsComponent = connect(mapStateToProps,mapDispatchToProps)(News);

export default withResizeContainer(NewsComponent)
