import React, { Component } from 'react'

class ResizeContainer extends Component {
  state = {
    imageSize: 'banner_desktop_image',
    limitText: 400
    
  } 
  resize = () => {
    let newLimitText = 400

    
    let bannerImage = 'banner_desktop_image'
    if (window.innerWidth > 1440){
      bannerImage = 'banner_desktop_image'
    }
    else if(window.innerWidth >= 800 && window.innerWidth <= 1440){
      bannerImage = 'banner_desktop_image'
      if(window.innerWidth <= 1024){
        newLimitText = 100
      }

    }
    else if(window.innerWidth >= 550 && window.innerWidth <= 800){
      bannerImage = 'banner_mobile_image'
      newLimitText = 60
    }
    else if(window.innerWidth < 550){
      bannerImage = 'banner_mobile_image'
    }
    this.setState({
      imageSize: bannerImage,
      limitText: newLimitText
    })
  }
  
  componentDidMount(){
    window.addEventListener("resize",this.resize)
    this.resize();
  }
  componentWillUnmount(){
    window.removeEventListener("resize",this.resize)
  }

  render() {
    const {component: Component, ...rest} = this.props
    return (
        <Component {...rest}
                   imageSize={this.state.imageSize}
                   limitText={this.state.limitText}
        />
    )
  }
}

const Container = ResizeContainer

export function withResizeContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
